import React, { useState } from "react";
import styled from "styled-components";

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Increase spacing on left and right */
  margin: 0 20px; /* Compensate for increased spacing */
`;

const GalleryItem = styled.div`
  margin: 8px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #ccc;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.3); /* Enlarge the photo more on hover */
  }
`;

const GalleryImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  display: block;
`;

const LargeImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const LargeImage = styled.img`
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
`;

const Gallery = ({ images }) => {
  const [largeImage, setLargeImage] = useState(null);

  const openLargeImage = (imageUrl) => {
    setLargeImage(imageUrl);
  };

  const closeLargeImage = () => {
    setLargeImage(null);
  };

  return (
    <div>
      <h2>Галерия Снимки</h2>
      <GalleryContainer className="gallery-container">
        {images.map((imageName, index) => {
          const imageUrl = `${process.env.PUBLIC_URL}/photos/${imageName}`;
          return (
            <GalleryItem
              key={index}
              className="gallery-item"
              onClick={() => openLargeImage(imageUrl)}
            >
              <GalleryImage src={imageUrl} alt={`Image ${index + 1}`} />
            </GalleryItem>
          );
        })}
      </GalleryContainer>

      {largeImage && (
        <LargeImageOverlay onClick={closeLargeImage}>
          <LargeImage src={largeImage} alt="Large Image" />
        </LargeImageOverlay>
      )}
    </div>
  );
};

export default Gallery;
