import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, useMediaQuery } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay } from 'date-fns';

const events = [
  { date: '2023-11-15', title: '', description: '' },
  { date: '2023-11-20', title: 'Обиколка с приятели', description: 'Сборен пункт пред клуба' },
  { date: '2024-01-20', title: 'Обиколка с приятели', description: 'Сборен пункт пред клуба' },
  // Add more events as needed
];

const Events = () => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  // Generate an array of days in the current month
  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(dateRange[0]),
    end: endOfMonth(dateRange[0]),
  });

  const blockStyle = {
    height: isMobile ? '15vh' : '20vh',
    width: isMobile ? '30vw' : '20vw',
    padding: '8px',
    margin: '4px',
  };

  return (
    <Box textAlign="center" mt={5}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={dateRange}
          onChange={handleDateChange}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <input {...startProps.inputProps} />
              <input {...endProps.inputProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
      <Box mt={3}>
        <Typography variant="h5" gutterBottom>
          Събития за {format(dateRange[0], 'MMMM yyyy')}
        </Typography>
        <Grid container spacing={1} sx={{justifyContent:"center"}}>
          {daysInMonth.map((day) => {
            const dayEvents = events.filter((event) => isSameMonth(new Date(event.date), day));

            return (
              <Grid item key={day.toISOString()}>
                <Paper elevation={3} style={blockStyle}>
                  <Typography variant="subtitle1">{format(day, 'd')}</Typography>
                  {dayEvents.length > 0 && isSameDay(day, new Date(dayEvents[0].date)) ? (
                    dayEvents.map((event) => (
                      <div key={event.date}>
                        <strong>{event.title}</strong>: {event.description}
                      </div>
                    ))
                  ) : (
                    <div key="no-event">Няма събитие</div>
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Events;
