export const postConfig = (data) => {
  const payload = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //body: JSON.stringify(data),
    },
  };
  return payload;
};

export const postFileConfig = (file, data) => {
  const formData = new FormData();
  formData.append(file);
  formData.append(data);
};
