import { useState } from 'react';

const useFetch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState(null)

  const fetchData = async (url,settings) => {
    setLoading(true);
    try {
      const response = await fetch(url,settings);
      const result = await response.json();
      setStatusCode(response.status);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setData(result);
    } catch (error) {
      console.log(error.message)
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, statusCode, fetchData };
};

export default useFetch;
