// Shop.js
import React, { useState } from 'react';

const tshirts = ['Тениска 1', 'Тениска 2', 'Тениска 3', 'Тениска 4'];
const hoodies = ['Елек 1', 'Елек 2', 'Елек 3', 'Елек 4'];

const Shop = () => {
  const [selectedCategory, setSelectedCategory] = useState('tshirts');
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setHoveredIndex(null); // Reset hovered index when category changes
  };

  const containerStyle = {
    textAlign:"center",
    justifyContent: "center",
    maxWidth: '800px',
    margin: '50px auto',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  const headerStyle = {
    textAlign: 'center',
    color: '#333',
  };

  const selectStyle = {
    padding: '8px',
  };

  const itemsGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    gap: '20px',
    marginTop: '20px',
  };

  const itemStyle = {
    border: '1px solid #ddd',
    padding: '10px',
    textAlign: 'center',
    backgroundColor: '#fff',
    transition: 'transform 0.3s',
    cursor: 'pointer',
  };

  const hoverItemStyle = {
    ...itemStyle,
    transform: 'scale(1.05)',
  };

  const items = selectedCategory === 'tshirts' ? tshirts : hoodies;

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>Магазин</h1>
      <label htmlFor="category">Изберете Категория: </label>
      <select id="category" value={selectedCategory} onChange={handleCategoryChange} style={selectStyle}>
        <option value="tshirts">Тениски</option>
        <option value="hoodies">Елеци</option>
      </select>
      <div style={itemsGridStyle}>
        {items.map((item, index) => (
          <div
            key={index}
            style={index === hoveredIndex ? hoverItemStyle : itemStyle}
            onMouseOver={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shop;
