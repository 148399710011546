import React from "react";
import "./App.css";
import DrawerAppBar from "./Components/Navbar";
import StickyFooter from "./Components/Footer";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./Components/AboutUs";
import NewsList from "./Components/Index";
import Shop from "./Components/Shop";
import Sponsors from "./Components/Sponsors";
import Events from "./Components/Events";
import Gallery from "./Components/Gallery";
let imagesContext;

if (process.env.NODE_ENV === 'development') {
  imagesContext = require.context("../public/photos", false, /\.(jpg|jpeg|png)$/);
} else {
  imagesContext = require.context("./files/photos", false, /\.(jpg|jpeg|png)$/);
}

const images = imagesContext.keys().map((key) => key.split("/").pop());

function App() {

  return (
    <div className="App">
      <DrawerAppBar />
      <div className="MainContent">
      <Routes>
        <Route exact path="/" element={<NewsList />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/sponsors" element={<Sponsors />} />
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/gallery" element={<Gallery images={images}/>} />

      </Routes>
      </div>
      <StickyFooter />
    </div>
  );
}

export default App;
