import * as React from "react";
import {CardActions, CardContent, Button, Typography, List, Card} from "@mui/material/";
import useFetch from './useFetch'; 
import { useEffect } from "react";
import { postConfig } from "../helpers/fetchConfig";
import { isDev, isITDC, isProd } from "../helpers/mode";

const exampleData = [
  {
    topic: "Екипировка",
    title: "Представяне на новият Nolan X-804 RS Ultra Carbon",
    text: "well meaning and kindly.",
  },
  {
    topic: "Екипировка",
    title: "Представяне на новият Nolan X-804 RS Ultra Carbon",
    text: "well meaning and kindly.",
  },
  {
    topic: "Екипировка",
    title: "Представяне на новият Nolan X-804 RS Ultra Carbon",
    text: "well meaning and kindly.",
  },
  {
    topic: "Екипировка",
    title: "Представяне на новият Nolan X-804 RS Ultra Carbon",
    text: "well meaning and kindly.",
  },
  {
    topic: "Екипировка",
    title: "Представяне на новият Nolan X-804 RS Ultra Carbon",
    text: "well meaning and kindly.",
  },
  {
    topic: "Екипировка",
    title: "Представяне на новият Nolan X-804 RS Ultra Carbon",
    text: "well meaning and kindly.",
  },
  {
    topic: "Екипировка",
    title: "Представяне на новият Nolan X-804 RS Ultra Carbon",
    text: "well meaning and kindly.",
  },
  {
    topic: "Екипировка",
    title: "Представяне на новият Nolan X-804 RS Ultra Carbon",
    text: "well meaning and kindly.",
  },
];


export default function NewsList() {
const api = isDev ? process.env.REACT_APP_NEWS_API_KEY_DEV : process.env.REACT_APP_NEWS_API_KEY_PROD;

const {data, loading, error, statusCode, fetchData} = useFetch();
const [users, setUsers] = ([data]);

  useEffect(() => {
  console.log(api)
  fetchData(api,postConfig())
  }, [])

  return (
    <div className="newsList" style={{}}>
      <h2 style={{ textAlign: "center", marginLeft: "10px", fontFamily:"Courier" }}>
        Последно добавени
      </h2>

      <List
        sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", background:"#fcfcfc" }}
      >
        {exampleData.map((item, index) => (
          <Card
            key={index}
            sx={{ width: "calc(20px +20vw)", height: "calc(20px +10vh)", margin: "5px" }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize:"calc(5px + 2vh)" }}
                color="text.secondary"
                gutterBottom
              >
                <b>{item.topic}</b>
              </Typography>
              <Typography component="div" sx={{ fontSize:"calc(1px + 2vh)" }}>
                {item.title}
              </Typography>
              <Typography variant="body1" sx={{ fontSize:"calc(1px + 2vh)" }}>{item.text}</Typography>
            </CardContent> 
            <CardActions sx={{justifyContent:"center"}}> 
              <Button size="small" sx={{position:"absolute", justifyContent:"center", marginBottom:"10px"}}>Прочети</Button>
            </CardActions>
          </Card>
        ))}
      </List>
    </div>
  );
}

