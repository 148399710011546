import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import backgroundImg from "../assets/archangelsPic.JPG";
import drawerbackground from "../assets/drawerbackground.jpeg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
const drawerWidth = 240;

const navItems1 = [
  { name: "Новини", linkName: "/" },
  { name: "Събития", linkName: "/events" },
  { name: "Галерия", linkName: "/gallery" },
];

const navItems2 = [
  { name: "За Нас", linkName: "/about" },
  { name: "Спонсори", linkName: "/sponsors" },
  { name: "Магазин", linkName: "/shop" },
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        backgroundColor: "#121212",
        height: "100%",
      }}
    >
      <Typography
        variant="h6"
        sx={{ my: 2, color: "white", fontFamily: "Poppins" }}
      >
        MCC Archangels
      </Typography>
      <Divider />
      <img src={drawerbackground} alt="drawerImg" width="240vw" />

      <List>
        {navItems1.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              justifyContent: "center",
              textDecoration: "none",
              color: "white",
            }}
          >
            <Link to={item.linkName} style={{ textDecoration: "none" }}>
              <ListItemButton>
                <ListItemText
                  primary={item.name}
                  sx={{
                    justifyContent: "center",
                    textDecoration: "none",
                    color: "white",
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        {navItems2.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ justifyContent: "center", textDecoration: "none" }}
          >
            <Link to={item.linkName} style={{ textDecoration: "none" }}>
              <ListItemButton>
                <ListItemText
                  primary={item.name}
                  sx={{
                    justifyContent: "center",
                    textDecoration: "none",
                    color: "white",
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="SocialIcons">
      <div
        style={{
          display: "flex",
          position: "absolute",
          right: "0",
          margin: "10px",
        }}
      >
        <a href="https://www.facebook.com/profile.php?id=100064636993891">
          <FacebookIcon
            style={{ fontSize: "calc(2px + 5vh)", color: "blue" }}
          />
        </a>
        <a href="https://www.instagram.com/">
          <InstagramIcon
            style={{ fontSize: "calc(2px + 5vh)", color: "#feda75" }}
          />
        </a>
      </div>

      <img
        component="button"
        src={backgroundImg}
        alt="mainImg"
        style={{
          width: "calc(100px + 30vw)",
          height: "calc(150px + 2vh)",
          marginTop: "5px",
        }}
      />
      <AppBar
        component="nav"
        color="transparent"
        elevation={0}
        sx={{ position: "absolute", marginTop: "20px" }}
      >
        <Toolbar sx={{ backgroundColor: "" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
            key={"drawerIcon"}
          >
            <MenuIcon key={"drawerMenuIcon"}/>
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block", textAlign: "left" },
            }}
             key={"drawerListItems"}
          >
            {navItems1.map((item, index) => (
              <Link to={item.linkName} key={index}>
                <Button
                  key={index}
                  sx={{
                    color: "black",
                    marginTop: "80px",
                    fontSize: "calc(3px + 2vh)",
                    borderBottom: "2px solid #eee",
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </Button>
              </Link>
            ))}{" "}
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems2.map((item, index) => (
              <Link to={item.linkName} key={index}>
                <Button
                  key={index}
                  sx={{
                    color: "black",
                    marginTop: "80px",
                    fontSize: "calc(3px + 2vh)",
                    borderBottom: "2px solid #eee",
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </div>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
