import React from 'react';

const AboutUs = () => {
  return (
    <div className="aboutUs" style={{margin:"20px"}}>
      <h3>Относно Нашият Мото Клуб</h3>
      <p>
        Добре дошли на официалната страница на Мото Клуб Архангели! 
        Ние не сме просто клуб, ние сме братство от райдъри, които споделят страстта си към открития път.
        Вярваме в свободата, която идва с шофирането, в приятелството, което се създава на магистралите,
        и в неописуемата връзка между тези, които живеят за усещането на вятъра в косата и дълбокия рев под моторите си.
      </p>
      <p>
        Нашият стил на каране не е просто да стигнем до дестинацията, той се отнася за самото пътуване.
        Без значение дали сте опитен райдър или току-що сте взели първия си мотоциклет, MCC Архангели е мястото, където райдъри на всички нива се събират.
        Организираме редовни покани, събития и срещи, за да отпразнуваме общата си любов към мотоциклетите.
        Присъединете се към нас и да обикаляме заедно, създавайки спомени, които ще останат завинаги.
      </p>
      <p>
        Архангели е повече от клуб, това е общност. Ние сме обединени от страстта към две колела, вълнението от шофирането
        и уважението, което имаме един към друг. Нашата разнообразна група от членове донесе различни стилове мотоциклети,
        произходи и истории, но всички споделяме нещо общо - любовта към свободата на открития път.
      </p>
      <p>
        Готови ли сте да се присъедините към приключението? Проверете нашия календар със събития, свържете се със съмишленици райдъри
        и станете част от семейството на MCC Архангели. Нека караме заедно и създадем спомени, които ще останат завинаги!
      </p>
    </div>
  );
};

export default AboutUs;