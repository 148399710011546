const { href } = window.location;

const setITDCAPIs = false;
const setProdAPIs = false;

export const isDev =
  href.includes("localhost") ||
  (href.includes("192.168.0") && !setITDCAPIs && setProdAPIs);

export const isITDC = href.includes("") || setITDCAPIs;
export const isProd = href.includes("") || setProdAPIs;
